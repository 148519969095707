<template>
  <KPaginatedAutocomplete v-bind="$attrs" v-on="$listeners" :paginator="autocomplete"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/modules/regions/api/regions';
import { mapGetters } from 'vuex';

export default {
  name: 'RegionsAutocomplete',
  components: { KPaginatedAutocomplete },
  computed: {
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }),
  },
  methods: {
    autocomplete() {
      return autocomplete(this.clientId)
    }
  },
};</script>
