import Model from './model';

export default class Region extends Model {
  id = 0;
  name = '';
  stores = [];

  mapForRequest() {
    return { ...this };
  }

  mapResponse(data, regionId) {
    return super.mapResponse({ id: regionId, ...data });
  }
}
