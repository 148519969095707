<template>
  <div>
    <k-crud-layout :search.sync="searchQuery" >
      <template #header>{{ $t('regions.menuTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile @click="openCreateDialog">{{ $t('regions.create') }}</v-btn>
        <RegionForm v-model="createDialog"
                    :request="create"
                    :title="$t('regions.create')"
                    :values="createFormValues"
                    @change="$refs.table.reload()"/>
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      language-prefix="regions.crudHeaders"
                      resource="regions">
          <template #actions="{item}">
            <v-btn icon>
              <v-icon @click="openUpdateDialog(item)">$edit</v-icon>
            </v-btn>
          </template>
        </k-crud-table>
      </template>
    </k-crud-layout>
    <RegionForm v-model="updateDialog"
              :request="update"
              :title="$t('regions.update')"
              :values="updateFormValues"
              is-update-form
              @change="$refs.table.reload()"/>
  </div>
</template>
<script lang="js">
import Region from '@/application/models/Region.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import { EventBus } from '@/application/EventBus.js';
import { mapGetters } from 'vuex';
import RegionForm from '@/modules/regions/views/RegionForm.vue';
import { create, update, index, show } from '@/modules/regions/api/regions.js';

export default {
  name: 'RegionsList',
  mixins: [querySyncMixin],
  components: {
    KCrudTable,
    KCrudLayout,
    RegionForm,
  },
  data() {
    return {
      filters: {},
      createDialog: false,
      updateDialog: false,
      createFormValues: new Region(),
      updateFormValues: new Region(),
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters('authorisation', { clientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [{
        value: 'name',
        language: 'name',
      }];
    },
  },
  methods: {
    reloadTable() {
      this.$refs.table.reload();
    },
    indexRequest(...args) {
      return index(this.clientId, ...args);
    },
    async create() {
      await create(this.clientId, this.createFormValues.mapForRequest());
      this.createDialog = false;
      this.reloadTable();
    },
    async update() {
      await update(this.clientId, this.updateFormValues.mapForRequest());
      this.updateDialog = false;
      this.reloadTable();
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    async openUpdateDialog(region) {
      const response = await show(this.clientId, region.id);
      this.updateFormValues = this.updateFormValues.mapResponse(response.data.data, region.id);
      this.updateDialog = true;
    },
  },
};</script>
