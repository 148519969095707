<template>
  <k-field-group language-prefix="regions.fields">
    <k-form-dialog :panels="panels" v-bind="$attrs" v-on="$listeners">
      <template #panel.0>
        <v-row class="fill-height" justify="start">
          <v-col cols="12">
            <form-sheet class="fill-height">
              <KTextField v-model="values.name" field="name" required/>
              <RegionsAutocomplete v-model="values.stores"
                                   :roleId="values.id"
                                   :return-object="true"
                                   chips
                                   deletable-chips
                                   field="stores"
                                   item-text="name"
                                   item-value="id"
                                   multiple
                                   small-chips/>
            </form-sheet>
          </v-col>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>
<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import RegionsAutocomplete from '@/modules/regions/components/RegionsAutocomplete.vue';
import FormSheet from '@/components/FormSheet.vue';

export default {
  name: 'RegionForm',
  components: {
    FormSheet,
    KFieldGroup,
    KFormDialog,
    KTextField,
    RegionsAutocomplete,
  },
  props: {
    values: {
      type: Object 
    } 
  },
  computed: {
    panels() {
      return [{ name: this.$t('global.defaultTabName') }];
    },
  },
};
</script>
