import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const autocomplete = async (clientId, ...args) =>{
  let result = await getPaginated(`store/autocomplete`, ...args);

  const index = await getPaginated(`client/${clientId}/region`, ...args)
  const nonAvailableStores = [...new Set(index.data.data.map(region => region.stores.map(store => store.id)).flat())]

  result.data.data = result.data.data.filter(store => !nonAvailableStores.includes(store.id))

  return result
}

export const index = (clientId, ...args) => getPaginated(`client/${clientId}/region`, ...args);

export const create = (clientId, region) => post(`client/${clientId}/region`, region);

export const show = (clientId, regionId) => get(`client/${clientId}/region/${regionId}`);

export const update = (clientId, region) => put(`client/${clientId}/region/${region.id}`, region);
